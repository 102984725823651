import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "gatsby";
import HTMLReactParser from 'html-react-parser';

const PopularSearchItem = (props) => {

    //console.log("props_item", props?.data?.Modules[0].Popular_Search_Module)
    return (

    <div className="footer-nav">
        {/* {
            props?.data?.popular_searches?.length > 0 && props?.data?.popular_searches?.map((item, index) =>{
                if(index < 4){
                    return(<>
                        {item.Content && HTMLReactParser(item.Content)}
                    </>
                    )
                }
            })
        } */}

        {
            props?.data && props?.data?.Modules?.length > 0 ? 
            (props?.data?.Modules[0]?.Popular_Search_Module?.length > 0 && props?.data?.Modules[0]?.Popular_Search_Module?.map((item, index) =>{
                if(index < 4){
                    return(<>
                        {item.popular_search.Content && HTMLReactParser(item.popular_search.Content)}
                    </>
                    )
                }
            })
            ) :
            (
                props?.data?.popular_searches?.length > 0 && props?.data?.popular_searches?.map((item, index) =>{
                    if(index < 4){
                        return(<>
                            {item.Content && HTMLReactParser(item.Content)}
                        </>
                        )
                    }
                })
            )
        }
    </div>

    );
};
export default PopularSearchItem;
