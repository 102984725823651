import axios from "axios"

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

// Property type
export const resiPropertyType = [
    {label: "Property Types", value: ""},
    {label: "Flat", value: "flat"},
    {label: "House", value: "house"},
]

export const commPropertyTypeSale = [
    {label: "Property Types", value: ""},
    {label: "Offices", value: "office"},
    {label: "Retail", value: "retail"},
    {label: "Leisure & Hospitality", value: "leisure-hospitality"},
    {label: "Industrial & Logistics", value: "industrial"},
    // {label: "Studio & Workshop", value: "studio"},
    {label: "Medical", value: "medical"},
    {label: "Educational", value: "educational"},
    {label: "Investment", value: "investment"},
    {label: "Land & Development", value: "land-development"},
    {label: "Other", value: "other"},
]

export const commPropertyTypRent = [
    {label: "Property Types", value: ""},
    {label: "Offices", value: "office"},
    {label: "Retail", value: "retail"},
    {label: "Leisure & Hospitality", value: "leisure-hospitality"},
    {label: "Industrial & Logistics", value: "industrial"},
    {label: "Studio & Workshop", value: "studio"},
    {label: "Studio & Gallery", value: "studio-gallery"},
    {label: "Medical", value: "medical"},
    {label: "Educational", value: "educational"},
    {label: "Land & Open Storage", value: "land"},
    {label: "Other", value: "other"},
]

// Property type

  
// Price Min Residential Sales
export const priceminRangeResiSale = [
    {label: "MIN PRICE", value: "0"},
    {label: "£300,000", value: "300000"},
    {label: "£350,000", value: "350000"},
    {label: "£400,000", value: "400000"},
    {label: "£450,000", value: "450000"},
    {label: "£500,000", value: "500000"},
    {label: "£550,000", value: "550000"},
    {label: "£600,000", value: "600000"},
    {label: "£700,000", value: "700000"},
    {label: "£800,000", value: "800000"},
    {label: "£900,000", value: "900000"},
    {label: "£1,000,000", value: "1000000"},
    {label: "£1,200,000", value: "1200000"},
    {label: "£1,500,000", value: "1500000"}
]
// Price Min Residential Sales
  
// Price Max Residential Sales
export const pricemaxRangeResiSale = [
    {label: "MAX PRICE", value: "0"},
    {label: "£1,500,000 +", value: "1500000"},
    {label: "£1,200,000", value: "1200000"},
    {label: "£1,000,000", value: "1000000"},
    {label: "£900,000", value: "900000"},
    {label: "£800,000", value: "800000"},
    {label: "£700,000", value: "700000"},
    {label: "£600,000", value: "600000"},
    {label: "£550,000", value: "550000"},
    {label: "£500,000", value: "500000"},
    {label: "£450,000", value: "450000"},
    {label: "£400,000", value: "400000"},
    {label: "£350,000", value: "350000"},
    {label: "£300,000", value: "300000"}
]
// Price Max Residential Sales
  
// Price Min Residential Lettings
export const priceminRangeResiRent = [
    {label: "MIN PRICE", value: "0"},
    {label: "£600 pcm", value: "600"},
    {label: "£800 pcm", value: "800"},
    {label: "£1,200 pcm", value: "1200"},
    {label: "£1,400 pcm", value: "1400"},
    {label: "£1,600 pcm", value: "1600"},
    {label: "£1,800 pcm", value: "1800"},
    {label: "£2,200 pcm", value: "2200"},
    {label: "£2,400 pcm", value: "2400"},
    {label: "£2,600 pcm", value: "2600"},
    {label: "£2,800 pcm", value: "2800"},
    {label: "£3,000 pcm", value: "3000"},
    {label: "£3,200 pcm", value: "3200"},
    {label: "£3,400 pcm", value: "3400"},
    {label: "£3,450 pcm", value: "3450"},
]
// Price Min Residential Lettings
  
// Price Max Residential Lettings
export const pricemaxRangeResiRent = [
    {label: "MAX PRICE", value: "0"},
    {label: "£3,450 pcm +", value: "3450"},
    {label: "£3,400 pcm", value: "3400"},
    {label: "£3,200 pcm", value: "3200"},
    {label: "£3,000 pcm", value: "3000"},
    {label: "£2,800 pcm", value: "2800"},
    {label: "£2,600 pcm", value: "2600"},
    {label: "£2,400 pcm", value: "2400"},
    {label: "£2,200 pcm", value: "2200"},
    {label: "£1,800 pcm", value: "1800"},
    {label: "£1,600 pcm", value: "1600"},
    {label: "£1,400 pcm", value: "1400"},
    {label: "£1,200 pcm", value: "1200"},
    {label: "£800 pcm", value: "800"},
    {label: "£600 pcm", value: "600"},
]
// Price Max Residential Lettings

// Property Size
export const PropertyMinSize = [
    // {label: "Any", value: "0"},
    {label: "MIN SIZE", value: "0"},
    {label: "1,000 sqft", value: "1000"},
    {label: "2,000 sqft", value: "2000"},
    {label: "3,000 sqft", value: "3000"},
    {label: "4,000 sqft", value: "4000"},
    {label: "5,000 sqft", value: "5000"},
    {label: "6,000 sqft", value: "6000"},
    {label: "7,000 sqft", value: "7000"},
    {label: "8,000 sqft", value: "8000"},
    {label: "9,000 sqft", value: "9000"},
    {label: "10,000 sqft", value: "10000"},

]

export const PropertyMaxSize = [
    // {label: "Any", value: "0"},
    {label: "MAX SIZE", value: "0"},
    {label: "10,000 sqft", value: "10000"},
    {label: "9,000 sqft", value: "9000"},
    {label: "8,000 sqft", value: "8000"},
    {label: "7,000 sqft", value: "7000"},
    {label: "6,000 sqft", value: "6000"},
    {label: "5,000 sqft", value: "5000"},
    {label: "4,000 sqft", value: "4000"},
    {label: "3,000 sqft", value: "3000"},
    {label: "2,000 sqft", value: "2000"},
    {label: "1,000 sqft", value: "1000"},
]
// Property Size
  
// Bedrooms
export const bedroomsRange = [
    // {label: "Any", value: "0"},
    {label: "Bedrooms", value: ""},
    {label: "Studio", value: "0"},
    {label: "1+", value: "1"},
    {label: "2+", value: "2"},
    {label: "3+", value: "3"},
    {label: "4+", value: "4"},
    {label: "5+", value: "5"},
    {label: "6+", value: "6"},
]
// Bedrooms

// Sort By
export const SortByValues = [
    { value: index_name+"_price_desc", label: "Highest Price First" },
    { value: index_name+"_price_asc", label: "Lowest Price First" },
]

export const SortByAuction = [
    { value: index_name+"_lot_asc", label: "Lowest Lot First" },
    // { value: index_name, label: "Recent First" },
    { value: index_name+"_price_desc", label: "Highest Price First" },
    { value: index_name+"_price_asc", label: "Lowest Price First" },
]

export const SortByCommercial = [
    { value: index_name+"_price_desc", label: "Highest Price First" },
    { value: index_name+"_price_asc", label: "Lowest Price First" },
    { value: index_name+"_size_desc", label: "Largest Size First" },
    { value: index_name+"_size_asc", label: "Smallest Size First" }
]
// Sort By


// search results url
export const SearchResultsURL = {
    residentialSales : "/residential-property/for-sale",
    residentialLettings : "/residential-property/to-rent",
}
// search results url