import React from 'react'
import { Form } from "react-bootstrap"

const RadioboxField = ({name, required, placeholder, values, handlechange, fieldClass, label}) => (
  <Form.Group controlId={`validation${name}`}>
    <div key={name}>
      { label
        ? <Form.Label className="form-label">{label}{required ? ' *' : ''}</Form.Label>
        : ''
      }
      <div className="form_radio">
        {values.map(val =>
          <Form.Check custom className={fieldClass} required={required} type="radio" label={val} name={name} value={val} id={val} onChange={handlechange} />
        )}
        </div>
    </div>
  </Form.Group>

);

export default RadioboxField;