import React from "react";
import Select, { components } from "react-select";
import { get, isString } from "lodash";
import "./SelectBox.scss";

const selectBox = props => {
  return (
    <div className={"select-group " + (props.isError ? "has-error" : "")}>
      <Select
        isSearchable={false}
        placeholder={props.placeHolder}
        aria-labelledby={props.id}
        value = {
          props.options.filter(option => 
             option.value === props.value)
        }
        onBlur={props.onBlur}
        onChange={props.handleChange}
        options={props.options}       
        classNamePrefix={"select-opt"}
        {...props.other}
      />
    </div>
  );
};

export default selectBox;
