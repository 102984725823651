import React, {useState} from "react";
import {gtag_report_conversion} from "../../components/common/pageUtils"
import RequestAppraisal from "../../components/forms/request_appraisal";
import Modal from "react-bootstrap/Modal"
import $ from "jquery"

const StickyCta = (props) => {

  const [isOpen,setIsOpen] = useState(false);
    
  const scrollToPos = (div_id) =>{
        var div_id_txt = div_id;
        if(div_id === "#contact" || div_id === "contact" || div_id === "#contact_office"){
            div_id_txt = "contact_office";
        }

        $('html, body').animate({
            scrollTop: $("#"+div_id_txt).offset().top - 200
        }, 'smooth');
    }
    //console.log("aliasss",props)

  return (
    <>
    <div className='sticky-cta-section '>
        
              {(props?.page?.Alias === "auctions" || props?.page?.Alias === "future-auctions" || props?.location?.includes("residential/residential-valuation")) &&
                <a href={`javascript:;`} onClick={() => {setIsOpen(true); gtag_report_conversion()}} className={`btn btn-primary`} >
                    <i className="right-slide"></i>
                    {props?.location?.includes("residential/residential-valuation")?"REQUEST VALUATION":"REQUEST APPRAISAL"}
                </a>}
                {
                  (props?.page?.Alias === "auction-buyers-guide" ||   props?.page?.Alias === "auction-sellers-guide") &&
                  <a href={"javascript:;"} onClick={(e)=>{scrollToPos("#contact"); gtag_report_conversion()}} className={`btn btn-primary`}>
                                                            <i className="right-slide"></i>
                            {props?.page?.Alias === "auction-buyers-guide" ? "ASK A QUESTION" : props?.page?.Alias === "auction-sellers-guide" ? "SPEAK TO THE TEAM" : ""}
                  </a>
                }
                
    </div>
    <Modal
    id="popup_modal_form"
    show={isOpen}
    onHide={() => setIsOpen(false)}
    backdrop="static"
    keyboard={false}
    className="member-contact-modal"
    > 
        <Modal.Header closeButton className="contact-close-btn memberPopup">
            <Modal.Title className="w-100">
                <h3 className="text-center content-primary-color mb-0">Request an Appraisal</h3>
                <p>Complete the form and one of our local experts will be in touch to arrange your market appraisal.
            </p>
            </Modal.Title>                    
        </Modal.Header>
        <Modal.Body>
            <RequestAppraisal department={props.department} page_url={props.page_url} type={props.type} sub_type={props.sub_type} to_user_id={props.to_user_id} popup_form={true} />
        </Modal.Body>
    </Modal>
    </>
  )
 
}

export default StickyCta