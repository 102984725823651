import * as qs from "query-string"
import axios from 'axios';

export const mail_form_url = `${process.env.GATSBY_CLOUD_API_URL}/form`;
export const campaign_monitor = `${process.env.GATSBY_CLOUD_API_URL}/campaign-monitor`;
var camp_monitor_token_api = process.env.GATSBY_CAMPAIGN_MONITOR_TOKEN_API;
var API_URL = process.env.GATSBY_STRAPI_SRC;

export const AuctionApi = {
  future: "/by-type/auctions?type=future",
  past: "/by-type/auctions?type=past",
  available_lots: "/available-lots/auctions",
  current_catalogue: "/current-catalogue/auctions",
}

export const Mail_Function = (formvalues) => {
  window.fetch(`${mail_form_url}`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(formvalues),
  })
}

export const CampaignMonitorDirect = async (formvalues) => {
  var resp = '';

  var res = await window.fetch(`${campaign_monitor}`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(formvalues),
  }).then((response) => {
    console.log("campaign res 1", response, response.status)
    resp = response;

    if(response.status === 500){
      CampaignMonitorDirect(formvalues)
    }
  })

  // console.log("campaign res", res.status, res)

  // if(res.status === 500){
  //   CampaignMonitorDirect(formvalues)
  // }
  

  return resp;
}

export const GetAuctions = async(link) => {
  const res = await axios.get(API_URL+link);
  return res;
}

export const CampaignMonitorToken = async(form_data) => {
  var data_id = "&data="+process.env.GATSBY_CAMPAIGN_MONITOR_DATA_ID;
  var api_link = camp_monitor_token_api+"?"+form_data+data_id;

  const res = await axios.post(api_link);
  return res;
}
export const CampaignMonitor = async(token_link, form_data) => {
  var api_link = token_link;
  
  //const res = await axios.post(api_link);
  //var res = await axios.post(api_link, qs.stringify(form_data))
  var res = window.fetch(`${api_link}`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/x-www-form-urlencoded;",
    },
    body: qs.stringify(form_data),
  })
  return res;
}

export const youtube_parser =(url) => {
  var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  var match = url.match(regExp);
  return (match && match[1].length==11)? match[1] : false;
}

export const getOffset = ( el ) => {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}


export const addCommas = num => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";

export  const removeNonNumeric = num => num ? num.toString().replace(/[^0-9]+/g, "") : "";

export  const numericWithDecimal = num => num ? num.toString().replace(/[^0-9\.]+/g, "") : "";

export const numberOnly = (value) => {
  if(value){
    if(!/^[0-9]+$/.test(value)){
      //alert("Please only enter numeric characters.")
      return "Please enter  only numeric characters.";
    }
  }  
}

export const addTrailingSlash = (link) => {
  var org_link = link;

  if(link){
    var check_slach = link?.trim().match(/\/$/)
    if(check_slach?.length > 0){
      org_link = link;
    } else{
      org_link = link+"/";
    }
  }
  
  return org_link;
}

export const externalURL = (link) => {
  if (link && (link.indexOf("http://") === 0 || link.indexOf("https://") === 0)) {
      return true;
  }
  else{
    return false;
  }
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export  const phoneNumberValidation = (num) =>  {
  if(num){
    return num.toString().replace(/[^0-9\+ ]+/g, "")
  } else {
    return false;
  } 
}

export const randomArrayShuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export const replaceString = (text_string) => {
  var find = ["FIND A PROPERTY", "FIND A SERVICE", "FIND A PERSON", "Find a property", "Find a service", "Find a person"];
  var replace = ["Find a Property", "Find a Service", "Find a Person", "Find a Property", "Find a Service", "Find a Person"];

  var replaceString = text_string;
  var regex; 
  for (var i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], "g");
    replaceString = replaceString.replace(regex, replace[i]);
  }
  return replaceString;
}

export const DynamicFormID = {
  residential: "3e76452b-4784-ec11-8d21-6045bd0f0447",
  commercial: "d49eb018-4784-ec11-8d21-6045bd0f0447",
  auction: "2221846c-219c-ea11-a812-00224801b559"
}


export const CampaignList = {
  "Auction Investments": "9e3c96234b53019f8510038c96f282b2",
  "Auction Updates": "2413150c439aae8a6b5ec74737e48178",
  "Commercial Investments": "a6660d953ee6871d9be50eea8cc8c2e1",
  "Commercial News": "eb51b520c09bec20ca689540d33857bf",
  "Residential Investments": "ca4cdcf06b98e614d4f0d397a3dde63d",
  "Residential News": "8690e92698f39c9fa862b3cc5f37d148",
}

export const AppraisalFormFieldMapping = {
  Type: {
    Agency: "200000000",
    Auctions: "200000001",
    Management: "200000002",
    VAS: "200000003",
  },
  SubType: {
    Agency: {
      'Acquisitions': "200000000",
      'Consultancy': "200000006",
      'Investment': "200000012",
      'Lettings': "200000014",
      'Sales': "200000020",
      'Valuations': "200000021",
      'Rent Review': "200000018",
      'Off Market': "200000029",
      'Other': "200000015",
      'Fit out referral': "100000000",
      'Third party referral': "100000001",
    },
    Auctions: {
      'Consultancy': "200000006",
      'Insurance': "200000011",
      'Investment': "200000012",
      'Receivership': "200000017",
      'Sales': "200000020",
      'Other': "200000015",
    },
    Management: {
      'Asset Management': "200000003",
      'Block Management': "200000004",
      'Commercial Management': "200000005",
      'Consultancy': "200000006",
      'Court/FTT attendance': "200000022",
      'Expert Witness': "200000010",
      'Insurance': "200000011",
      'Lease Renewal': "200000013",
      'Licence to Alter': "200000025",
      'Licence to Assign': "200000026",
      'Licence to Sub': "200000027",
      'Major/Building works': "200000028",
      'Other': "200000015",
      'Rating': "200000016",
      'Rent Review': "200000018",
      'Residential Management': "200000019",
      'Valuations': "200000021",
      'Health': "200000033",
    },
    VAS: {
      'Affordable Housing':"200000001",
      'Arbitration/Independent Expert':"200000002",
      'Consultancy':"200000006",
      'CPO':"200000007",
      'Development Consultancy':"200000023",
      'Development/Viability':"200000008",
      'Enfranchisement':"200000009",
      'Expert Witness':"200000010",
      'Insurance':"200000011",
      'Lease Renewal':"200000013",
      'Rating':"200000016",
      'Receivership':"200000017",
      'Rent Review':"200000018",
      'Valuations':"200000021",
      'Other':"200000015",
      'Valuations - Loan Security':"200000032",
      'Valuations - Other/Red Book':"200000031",
    }
  }
}

export const CustomLinks = {
  our_people: "/our-people",
  find_team: "/find-team",
  join_us: "/join-us",
  insights: "/insights",
  case_studies: "/case-studies",
  landlord_resource_center: "/landlord-resource-center",
  terms_conditions: "/terms-and-conditions/",
  privacy_policy: "/privacy-policy/",
  contact: "/contact",
  get_in_touch: "/contact-us",
  contact_form: "/contact-strettons/",
  appraisal_from: "/request-an-appraisal/",
  newsletter_subscription: "/newsletter-subscription/",
  future_auction_details: "/auctions/future-auctions/future-auction-details",
  // past_auction_details: "/auctions/past-auctions/past-auction-details",
  past_auction_details: "/auctions/past-auction-details",
  auction_property_for_sale: "/auction-property-for-sale",
  current_catalogue: "/auctions/current-catalogue/",
  available_lots: "/auctions/available-lots/",
  past_auction_details: "/auctions/past-auctions/past-auction-details/"
}