import React, {useState} from "react";
import Loadable from "@loadable/component"
import {menuLinks} from "../../common/pageUtils";
import { Link } from "gatsby";
const LocalSiteSearch = Loadable(() => import ('./LocalSiteSearch'))
// const menuLinks = Loadable(() => import ('../../common/pageUtils'))



const SiteSearch = (props) => {

  const[search_result, setSearchResult] = useState('');
  
  const searchResult = (result) =>{
    setSearchResult(result);
  }
  return (
    <>
        <div className="site_srch">
            <LocalSiteSearch  searchResult={searchResult}/>
        </div>

        {
            search_result &&             
            <div className="service-box">
                <div className="box">
                    <div className="h3">
                        <a>{"Services"} ({search_result["Services"].length})</a>
                    </div>

                    {/* ========= Main Parent ===== */}
                    {
                        search_result["Services"].filter(item => item?.menu?.Main_Parent === null && item?.menu?.Secondary_Menu === null && item?.menu?.nested_menus === null).map((item) =>{
                            return(
                                <div className="h2">
                                    <Link to={item.menu ? menuLinks(item.menu) : ''}>{item.title}</Link>
                                </div>
                            )
                        })
                    }

                    {/* ========= Secondary Menu ===== */}
                    {
                        search_result["Services"].filter(item => item?.menu?.Main_Parent !== null && item?.menu?.Secondary_Menu === null && item?.menu?.nested_menus === null).map((item) =>{
                            return(
                                <div className="h2">
                                    <Link to={item.menu ? menuLinks(item.menu) : ''}>{item.title}</Link>
                                </div>
                            )
                        })
                    }

                    {/* ========= Third Level Menu ===== */}
                    {
                        search_result["Services"].filter(item => item?.menu?.Main_Parent !== null && item?.menu?.Secondary_Menu !== null && item?.menu?.nested_menus === null).map((item) =>{
                            return(
                                <div className="h2">
                                    <Link to={item.menu ? menuLinks(item.menu) : ''}>{item.title}</Link>
                                </div>
                            )
                        })
                    }

                    {/* ========= Last Level Menu ===== */}
                    {
                        search_result["Services"].filter(item => item?.menu?.Main_Parent !== null && item?.menu?.Secondary_Menu !== null && item?.menu?.nested_menus !== null).map((item) =>{
                            return(
                                <div className="h2">
                                    <Link to={item.menu ? menuLinks(item.menu) : ''}>{item.title}</Link>
                                </div>
                            )
                        })
                    }
                </div>

                {
                    Object.keys(search_result).filter(key => key !== "Services").map((key) =>{
                        //console.log("item", key, search_result[key]);
                        if(search_result[key].length > 0){
                            return(
                                <div className="box">
                                    <div className="h3">
                                        <a>{key} ({search_result[key].length})</a>
                                    </div>

                                    {
                                        search_result[key].map((item) =>{
                                            
                                            return(
                                                <div className="h2">
                                                    <Link to={item.menu.URL}>{item.title}</Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        
                    })
                }
                
            </div>
        }
        
    </>
  );
};
export default SiteSearch;