import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col} from "react-bootstrap";
import { Helmet } from "react-helmet";
import Cookies from 'universal-cookie';
// import HTMLReactParser from 'html-react-parser';

import $ from "jquery";

import useWindowSize from "../common/useWindowSize";
import MainMenu from "./Sections/MainMenu";
import PopupMenu from "./Sections/PopupMenu";
import "./Header.scss";

import logo from "../../images/strettons-logo.svg";

import logo02 from "../../images/searchlist/strettons-logo.svg";

import Tablogo from "../../images/strettons-logo-tab.svg";

import Desklogo from "../../images/strettons-logo-desktop.svg";
import useHasScrolled from "./useHasScrolled";
// Header component

const Header = (props) => {
  const [search, setSearch] = useState(false);
  const scroll = useHasScrolled();
  const [open, setOpen] = useState(false);
  const [menu_heading, setMenuHeading] = useState(false);
  const [logoHeight, setLogoHeight] = useState(64);
  const [logoWidth, setLogoWidth] = useState(272);   

  const { width } = useWindowSize();

  const cookies = new Cookies();

  useEffect(() => { 
    $("a[href='#']").removeAttr("href");
  }, [])
  
  useEffect(() => {
    if(typeof window !== "undefined") {

      const script1 = document.createElement('script');
      script1.type = "text/javascript";
      script1.innerHTML = `
        var $wc_load = function(a) { return JSON.parse(JSON.stringify(a)) };
        var $wc_leads = $wc_leads || {
          doc: {
            url: $wc_load(document.URL),
            ref: $wc_load(document.referrer),
            search: $wc_load(location.search),
            hash: $wc_load(location.hash)
          }
        };
      `;
      document.getElementsByTagName('head')[0].appendChild(script1);

      const script2 = document.createElement('script');
      script2.type = "text/javascript";
      script2.src = "//s.ksrndkehqnwntyxlhgto.com/109948.js";
      document.getElementsByTagName('head')[0].appendChild(script2);
      
      const script3 = document.createElement('script');
      script3.type = "text/javascript";
      script3.src = "https://secure.intelligentdatawisdom.com/js/782344.js";
      document.getElementsByTagName('head')[0].appendChild(script3);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = '<img alt="" src="https://secure.intelligentdatawisdom.com/782344.png" style="display:none;" />';
      document.getElementsByTagName('body')[0].appendChild(noscript);
    }
  }, [])

  useEffect(() => {
    if(width < parseInt(768)){
        setLogoHeight(32)
      setLogoWidth(136)
    }else if( width >= 769 && width <= 1600){
      setLogoHeight(56)
      setLogoWidth(234)
    }

    // try {
    //   window.addEventListener("scroll", () => {
    //     if(width < 1200){          
    //       setScroll(window.scrollY > 30);
    //     } else if(width > 1200 && width < 1400){
    //       setScroll(window.scrollY > 70);
    //     } else{
    //       setScroll(window.scrollY > 120);
    //     }
    //   });
    // } catch {}
  }, [width]);

  useEffect(() => {
      // console.log("cookies => 1", cookies?.get('cookieyes-functional'));

      setTimeout(() =>{
        if(cookies?.get('cookieyes-functional') === "yes"){

          $("#cky-consent").addClass("hide");
          $("#cky-consent-toggler").addClass("hide");
          $("#cky-consent-toggler").remove();
          $("#cky-consent").remove();
          
          console.log("cookies => 2", cookies?.get('cookieyes-functional'));
        }
      },2000)

  }, [cookies]);

  const openHam = () => {
    setOpen(!open);
  };

  const openSearch = (menu_title) => {
    setSearch(!search);
    props.handlerClick();
    setMenuHeading(menu_title)
  };
  const closeSearch = () => {
    setSearch(!search);

    $('body').removeClass('overflow-hidden');

  };
  var menu_data = props.site_menu;

  const AppendScript = (url, id, appendTo, callback) => {
    if (document.getElementById(id) === null) {
      var e = document.createElement("script");
      e.src = url;
      e.id = id;
      e.async = true;
      e.defer = true;
      e.setAttribute("data-blockingmode", "auto");
      e.setAttribute("data-cbid", "791e1d83-c9bc-4f2d-aa52-4b1796caf4ea"); 
      e.type = "text/javascript";
      e.addEventListener('load', callback);
      document.getElementsByTagName(appendTo)[0].appendChild(e);
    
    }
  }

  const cookieBotChecker = () =>{
    if(typeof window !== "undefined") {
      let cookieBotActive = document.getElementsByClassName('CybotCookiebotDialogActive')
      let cookieBotActiveBody = document.getElementsByClassName('cookieBotActive')
      if(cookieBotActive.length > 1 && cookieBotActiveBody.length == 0){ 
        document.body.className += " cookieBotActive ";
      } else if(cookieBotActiveBody.length > 0 && cookieBotActive.length < 2){ 
        document.body.className = document.body.className.replace("cookieBotActive","");
      }
    }
  }

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
        cookieBotChecker()
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
        cookieBotChecker()
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
        cookieBotChecker()
      }
    })
    AppendScript("https://consent.cookiebot.com/uc.js", "Cookiebot", "head");  
    // AppendScript("https://consent.cookiebot.com/791e1d83-c9bc-4f2d-aa52-4b1796caf4ea/cd.js", "CookieDeclaration","body");  
 
  }, [])

  return (
    <React.Fragment>
      
        <Helmet>
          {process.env.GATSBY_ALGOLIA_INDEX_NAME === "dev_properties_disabled" &&
            <script defer type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=xdmj3tmae7s9nnvrsjk3vg" async="true"></script>
          }
          {/* {typeof window !== "undefined" && (script_tag ? HTMLReactParser(script_tag) : "")} */}
          {renderComponent && <script defer={true} src="https://e0d1621f969c46f4957a25b7c3004187.js.ubembed.com" /> } 
        </Helmet>
      <header className={scroll ? "header fixed" : "header"}>
        <Container>
          <Row>
            <Col>
              <div
                className={
                  open ? "header-wrapper open-menu" : " header-wrapper"
                }
              >
                <div className="logo">
                  <Link to="/">
                    <picture>
                      <source media="(min-width:1800px)" srcSet={Desklogo} />
                      <source media="(min-width:768px)" srcSet={Tablogo} />
                      <img src={logo} alt="Strettons logo" loading="lazy" width={logoWidth} height={logoHeight} />
                    </picture>
                  </Link>
                </div>
                
                <div className="logo logo-dark d-none">
                  <Link to="/"><img src={logo02} alt="Strettons logo" loading="lazy" width={logoWidth} height={logoHeight}/></Link>

                </div>

                <MainMenu openSearch={openSearch} openHam={openHam} search={search} open={open} menu_data={menu_data} Alias={props?.Alias} />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      
      {
        search && 
        <PopupMenu closeSearch={closeSearch} handlerClick={props.handlerClick} menu_heading={menu_heading} search={search} open={open} menu_data={menu_data} />
      }
    </React.Fragment>
  );
};
export default Header;
