import axios from "axios"

export const menuLinks = (menu_data) => {
    var menu_link = '';

    // if(menu_data.Secondary_URL){
    //     menu_link = menu_data.Secondary_URL+'/';
    // } else if(menu_data.Main_Parent === null && menu_data.Secondary_Menu === null){
    //     menu_link = '/'+menu_data.Primary_URL+'/';
    // } else if(menu_data.Main_Parent !== null && menu_data.Secondary_Menu === null){
    //     menu_link = '/'+menu_data.Main_Parent.Primary_URL+'/'+menu_data.Primary_URL+'/';
    // } else{
    //     menu_link = '/'+menu_data.Main_Parent.Primary_URL+'/'+menu_data.Secondary_Menu.Primary_URL+'/'+menu_data.Primary_URL+'/';
    // }

    if(menu_data.Main_Parent !== null && menu_data.Secondary_Menu !== null && menu_data.nested_menus !== null){
        menu_link = '/'+menu_data.Main_Parent.Primary_URL+'/'+menu_data.Secondary_Menu.Primary_URL+'/'+menu_data.nested_menus.Primary_URL+'/'+menu_data.Primary_URL+'/';
    } else if(menu_data.Main_Parent !== null && menu_data.Secondary_Menu !== null){
        menu_link = '/'+menu_data.Main_Parent.Primary_URL+'/'+menu_data.Secondary_Menu.Primary_URL+'/'+menu_data.Primary_URL+'/';
    } else if(menu_data.Main_Parent !== null){
        menu_link = '/'+menu_data.Main_Parent.Primary_URL+'/'+menu_data.Primary_URL+'/';
    } else{
        menu_link = '/'+menu_data.Primary_URL+'/';
    }

    return menu_link;
  }

export const  gtag_report_conversion = (url) => {
    console.log("event_track begin")
    // var callback = function () {
    //     if (typeof window !== "undefined" && typeof(url) !== 'undefined') {
    //         window.location = url;
    //     }
    // };
    if (typeof window !== "undefined" && typeof window.gtag !== 'undefined'){
        typeof window !== "undefined" && window.gtag('event', 'conversion', {
        'send_to': 'AW-10810490027/YntaCLCcrqUDEKv566Io',
        //'event_callback': callback
        });
    }
    console.log("event_track end")
    
    return false;
}