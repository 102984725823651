import React, { useState } from "react";
import { Form, Button, Tabs, Tab, } from "react-bootstrap";
import $ from "jquery";
import { navigate } from "@reach/router"
import SearchResultInputBox from './SearchResultsBox';
import { resiPropertyType, commPropertyTypeSale, commPropertyTypRent } from "../../common/propertyUtils";
import SelectOption from "../../SelectBox/SelectBox";

import { useContainerContext } from "../../../store/ContainerContext";

import "./searchbox.scss"

const PropertySearch = (props) => {
    const [propertyval, setPropertyVal] = useState('');

    const context = useContainerContext();
    const { search_list_department } = context.state;
    const { SearchListDepartDispatch } = context.actions;

    var prop_type = '';
    if (propertyval) {
        prop_type = 'type-' + propertyval + '/';
    }


    // Residential
    const resiBuy = () => {
        var areaValue = $(".resiSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue) {
            searchFilterResults = "/residential-property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/residential-property/for-sale/in-london/";
        }

        searchFilterResults = searchFilterResults + prop_type;

        SearchListDepartDispatch("");

        navigate(searchFilterResults);

        props.closeSearch();
    }

    const resiLet = () => {
        var areaValue = $(".resiSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue) {
            searchFilterResults = "/residential-property/to-rent/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/residential-property/to-rent/in-london/";
        }

        searchFilterResults = searchFilterResults + prop_type;

        SearchListDepartDispatch("");

        navigate(searchFilterResults);

        props.closeSearch();
    }
    // Residential

    // Commercial
    const commBuy = (val) => {
        var areaValue = $(".commSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue) {
            searchFilterResults = "/commercial-property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/commercial-property/for-sale/in-london/";
        }

        if (val) {
            searchFilterResults = searchFilterResults + 'type-' + val + '/';
        } else {
            searchFilterResults = searchFilterResults + prop_type;
        }

        //console.log("val", val)
        SearchListDepartDispatch(val);

        navigate(searchFilterResults);

        props.closeSearch();

    }

    const commLet = (val) => {
        var areaValue = $(".commSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue) {
            searchFilterResults = "/commercial-property/to-rent/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/commercial-property/to-rent/in-london/";
        }

        if (val) {
            searchFilterResults = searchFilterResults + 'type-' + val + '/';
        } else {
            searchFilterResults = searchFilterResults + prop_type;
        }

        SearchListDepartDispatch("");

        navigate(searchFilterResults);

        props.closeSearch();
    }
    // Commercial

     // Commercial Industrial
     const commIndustrialBuy = (val) => {
        var areaValue = $(".commIndustrialSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue) {
            searchFilterResults = "/commercial-property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/commercial-property/for-sale/in-uk/";
        }

        if (val) {
            searchFilterResults = searchFilterResults + 'type-' + val + '/';
        } else {
            searchFilterResults = searchFilterResults + prop_type;
        }

        //console.log("val", val)
        SearchListDepartDispatch(val);

        navigate(searchFilterResults);

        props.closeSearch();

    }

    const commIndustrialLet = (val) => {
        var areaValue = $(".commIndustrialSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue) {
            searchFilterResults = "/commercial-property/to-rent/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/commercial-property/to-rent/in-uk/";
        }

        if (val) {
            searchFilterResults = searchFilterResults + 'type-' + val + '/';
        } else {
            searchFilterResults = searchFilterResults + prop_type;
        }

        SearchListDepartDispatch("");

        navigate(searchFilterResults);

        props.closeSearch();
    }
    // Commercial


    // Investment
    const commBuyInvest = (val) => {

        var searchFilterResults = "/commercial-property/for-sale/in-london/";

        // var areaValue = $("#investment .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        // if(areaValue){
        //     searchFilterResults = "/commercial-property/for-sale/in-" + areaValue + "/";
        // } else {
        //     searchFilterResults = "/commercial-property/for-sale/in-london/";
        // }

        if (val) {
            searchFilterResults = searchFilterResults + 'type-' + val + '/';
        } else {
            searchFilterResults = searchFilterResults + prop_type + '/';
        }

        //console.log("val", val)
        SearchListDepartDispatch(val);

        navigate(searchFilterResults);

        props.closeSearch();
    }
    // Investment


    // Auction
    const auctionBtn = () => {
        var areaValue = $(".auctionSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue && areaValue.toLowerCase() !== "uk") {
            searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/auction-property/for-sale/in-uk/";
        }

        SearchListDepartDispatch("");

        navigate(searchFilterResults);
    }

    const auctionComm = () => {
        var areaValue = $(".auctionSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue && areaValue.toLowerCase() !== "uk") {
            searchFilterResults = "/auction-commercial-property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/auction-commercial-property/for-sale/in-uk/";
        }

        SearchListDepartDispatch("");

        navigate(searchFilterResults);

        props.closeSearch();
    }

    const auctionResi = () => {
        var areaValue = $(".auctionSearchBox .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        var searchFilterResults = "";

        if (areaValue && areaValue.toLowerCase() !== "uk") {
            searchFilterResults = "/auction-residential-property/for-sale/in-" + areaValue + "/";
        } else {
            searchFilterResults = "/auction-residential-property/for-sale/in-uk/";
        }

        SearchListDepartDispatch("");

        navigate(searchFilterResults);

        props.closeSearch();
    }
    // Auction

    //console.log("search_list_department", search_list_department);

    return (
        <Tabs defaultActiveKey="commercial">
            <Tab eventKey="commercial" title="commercial">
                <Form>
                    <div className="top-content commSearchBox" >
                        {/* <Form.Group>
                        <Form.Control
                            className="search-field full_wdth"
                            type="text"
                            placeholder="Street, location or postcode"
                        />
                    </Form.Group> */}
                        <SearchResultInputBox placeHolder={`Street, property name, location or postcode`} department={"commercial"} />
                        <div className="btn-wrapper d-flex">
                            <Button onClick={() => commBuy('')} className="me-3">buy</Button> <Button onClick={() => commLet('')}>lease</Button>
                            {/* <Button>both</Button>{" "} */}
                        </div>
                    </div>
                    <div className="category-select property">
                        {/* <label>Property Type</label>
                     */}
                        <SelectOption
                            options={commPropertyTypeSale.filter(item => item.label !== "Property Types")}
                            value={commPropertyTypeSale.find(obj => obj.value === propertyval)}
                            placeHolder={propertyval ? propertyval : "property types"}
                            handleChange={e => setPropertyVal(e.value)}
                        />
                    </div>
                </Form>
            </Tab>
            <Tab eventKey="auctions" title="auctions">
                <Form>
                    <div className="top-content auctionSearchBox">
                        {/* <Form.Group>
                        <Form.Control
                            className="search-field full_wdth"
                            type="text"
                            placeholder="Street, location or postcode"
                        />
                    </Form.Group> */}
                        <SearchResultInputBox placeHolder={`Street, property name, location or postcode`} department={"auction"} />
                        <div className="btn-wrapper auctions_btns">
                            <Button onClick={auctionComm}>Commercial</Button> <Button onClick={auctionResi}>Residential</Button>{" "}
                            <Button onClick={auctionBtn}>both</Button>{" "}
                        </div>
                    </div>
                </Form>
            </Tab>
            <Tab eventKey="investment" title="investment" >
                <Form>
                    <div className="top-content commSearchBox" id="investment">
                        {/* <Form.Group>
                        <Form.Control
                            className="search-field full_wdth"
                            type="text"
                            placeholder="Street, location or postcode"
                        />
                    </Form.Group> */}
                        {/* <SearchResultInputBox placeHolder={`Street, property name, location or postcode`} department={"commercial"} /> */}
                        <div className="btn-wrapper d-flex invest_btn">
                            <Button onClick={() => commBuyInvest('investment')} className="me-3">VIEW ALL INVESTMENTS</Button>
                            {/* <Button>both</Button>{" "} */}
                        </div>
                    </div>
                </Form>
            </Tab>
            <Tab eventKey="industrial" title="industrial">
                <Form>
                    <div className="top-content commIndustrialSearchBox" >
                        {/* <Form.Group>
                        <Form.Control
                            className="search-field full_wdth"
                            type="text"
                            placeholder="Street, location or postcode"
                        />
                    </Form.Group> */}
                        <SearchResultInputBox placeHolder={`Street, property name, location or postcode`} department={"commercial"} />
                        <div className="btn-wrapper d-flex">
                            <Button onClick={() => commIndustrialBuy('industrial')} className="me-3">buy</Button> <Button onClick={() => commIndustrialLet('industrial')}>lease</Button>
                            {/* <Button>both</Button>{" "} */}
                        </div>
                    </div>
                    {/* <div className="category-select property">
                   
                    <SelectOption
                        options={commPropertyTypeSale.filter(item => item.label !== "Property Types")}
                        value={commPropertyTypeSale.find(obj => obj.value === propertyval)}
                        placeHolder={propertyval ? propertyval : "property types"}
                        handleChange={e => setPropertyVal(e.value)}
                    />
                </div> */}
                </Form>
            </Tab>
            <Tab eventKey="residential" title="residential">
                <Form>
                    <div className="top-content resiSearchBox">
                        {/* <Form.Group>
                        <Form.Control
                            className="search-field full_wdth"
                            type="text"
                            placeholder="Street, location or postcode"
                        />
                    </Form.Group> */}
                        <SearchResultInputBox placeHolder={`Street, property name, location or postcode`} department={"residential"} />
                        <div className="btn-wrapper d-flex">
                            <Button className="me-3" onClick={resiBuy}>buy</Button> <Button onClick={resiLet}>lease</Button>{" "}
                            {/* <Button>both</Button>{" "} */}
                        </div>
                    </div>
                    {/* <div className="category-select property">
                    <SelectOption
                        options={resiPropertyType.filter(item => item.label !== "Property Types")}
                        value={resiPropertyType.find(obj => obj.value === propertyval)}
                        placeHolder={propertyval ? propertyval : "property types"}
                        handleChange={e => setPropertyVal(e.value)}
                    />
                </div> */}
                </Form>
            </Tab>

        </Tabs>
    );
};
export default PropertySearch;
