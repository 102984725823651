import React from 'react'
import './FloatingNumber.scss'
import phoneIcon from '../../../images/phone.svg'

const FloatingNumber = (props) => {


  var phoneNumber = props.Module.Phone_Number.replaceAll(" ", "")

  return (
    <>{
        props.Module.Publish && 
        <>
        <div className='section-right-sticky'>
            <div className='auction_number_floatcontainer'>
              <div className='auction_number'>
                <img src={phoneIcon} alt="phoneIcon"/>
                <a href={`tel:${phoneNumber}`}>{props.Module.Phone_Number}</a>
              </div>
            </div>
          </div>
          <div className='section-bottom-sticky'>
            <div className='auction_number_floatcontainer give-a-call'>
              <div className='auction_number'>
                <a href={`tel:${phoneNumber}`}><i className="icon-auction-call"></i></a>
              </div>
            </div>
          </div>
        </>
    }</>
  )
}

export default FloatingNumber