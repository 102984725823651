import { useQuery} from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Queries } from "./common_queries";

export const Insights = () => {
    return useQuery(Queries.Insights);
}

export const InsightsFilters = () => {
    return useQuery(Queries.InsightsFilters);
}

export const FeatureInsights = (filter_category) => {
    return useQuery(Queries.FeatureInsights,{
        variables: {filter_category: filter_category}
    });
}

export const RelatedInsights = (service) => {
    return useQuery(Queries.RelatedInsights,{
        variables: {service: service}
    });
}


export const Insight_Details = (slug) => {
    return useQuery(Queries.Insight_Details, {
        variables: {slug: slug}
    });
}

export const People_Team = (search_text) => {
    return useQuery(Queries.People_Team);
}

export const People_Email = (email_id) => {
    return useQuery(Queries.People_Email,{
        variables: {email_id: email_id}
    });
}

export const People_NegoID = (nego_id) => {
    return useQuery(Queries.People_NegoID,{
        variables: {nego_id: nego_id}
    });
}

export const People_Filter = () => {
    return useQuery(Queries.People_Filter);
}

export const Home_People = () => {
    return useQuery(Queries.Home_People);
}

export const People_Details = (slug) => {
    return useQuery(Queries.People_Details, {
        variables: {slug: slug}
    });
}

export const GetOffices = () => {
    return useQuery(Queries.GetOffices);
}

export const Office_Details = (slug) => {
    return useQuery(Queries.Office_Details, {
        variables: {slug: slug}
    });
}

export const All_Clients = () => {
    return useQuery(Queries.All_Clients);
}

export const Vacancies = () => {
    return useQuery(Queries.Vacancies);
}

export const VacancyDetails = (slug) => {
    return useQuery(Queries.VacancyDetails, {
        variables: {slug: slug}
    });
}

export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department}
    });
}

export const NoResultPropertiesAuctions = (department) => {
    return useQuery(Queries.NoResultPropertiesAuctions, {
        variables: {department: department}
    });
}

export const FeaturedProperties = (status, department) => {
    return useQuery(Queries.FeaturedProperties, {
        variables: {status: status, department: department}
    });
}

export const AuctionList = (status) => {
    return useQuery(Queries.FuturAuctionList,{
        variables: {status: status}
    });
}

export const PastAuctionList = (status) => {
    return useQuery(Queries.PastAuctionList,{
        variables: {status: status}
    });
}

export const AllAuctions = (status, sort_order) => {
    return useQuery(Queries.AllAuctions,{
        variables: {status: status, sort_order: sort_order}
    });
}

export const AuctionsPropertyPage = () => {
    return useQuery(Queries.AuctionsPropertyPage);
}

export const PopularSearch = (slug) => {
    return useQuery(Queries.PopularSearch,{
        variables: {slug: slug}
    });
}

export const SimilarPropertiesAuctions = (area, department, auctionType) => {
    return useQuery(Queries.SimilarPropertiesAuctions, {
        variables: {area: area, department: department, auctionType: auctionType}
    });
}

export const AllProperties = (department) => {
    return useQuery(Queries.AllProperties, {
        variables: {department: department}
    });
}

export const GetPropByCRM = (crm_id) => {
    return useQuery(Queries.GetPropByCRM, {
        variables: {crm_id: crm_id}
    });
}

export const AllDepartments = () => {
    return useQuery(Queries.AllDepartments);
}

export const AllServices = () => {
    return useQuery(Queries.AllServices);
}

export const AllDepartmentsInsights = () => {
    return useQuery(Queries.AllDepartmentsInsights);
}

export const AllNewsAndInsightsCategories = () => {
    return useQuery(Queries.AllNewsAndInsightsCategories);
}

export const NewsAndInsight = (category_name) => {
    return useQuery(Queries.NewsAndInsight, {
        variables: {category_name: category_name}
    });
}

export const AuctionIds = () => {
    return useQuery(Queries.AuctionIds);
}

export const GetAuctionProperties = (auctionId1, auctionId2, auction_residential, auction_commercial) => {
    return useQuery(Queries.GetAuctionProperties, {
        variables: {auctionId1: auctionId1, auctionId2: auctionId2, auction_residential: auction_residential, auction_commercial: auction_commercial}
    });
}
