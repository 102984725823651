import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"


import PopularSearchItem from "./Sections/PopularSearchItem";

import {PopularSearch} from "../../queries/common_use_query";

const PopularSearchSection = (props) => {
    const [search, setSearch] = useState(false);
    
    const openPopular = () => {
        setSearch(!search);
    };

    var data = useStaticQuery(graphql`
    query MyQueryPopularSearch {
      glstrapi {
          allMenus(where: {Primary_URL: "home"}) {
            Modules {
              ... on GLSTRAPI_ComponentComponentPopularSearchComponent {
                id
                Popular_Search_Module {
                  popular_search {
                    Content
                    Name
                  }
                }
              }
            }
          }
        }      
      }                
  `)
    if(props.slug === "home"){
      data = data?.glstrapi;
    }else{
      var { loading, error, data } = PopularSearch(props.slug);
    }
    return (

      <div className={search ? "menu-wrapper popular active" : "menu-wrapper popular"}>
        <h5 onClick={openPopular}>
        Popular Searches
        <a href="javascript:;">
            <i className={search ? "icon-minus" : "icon-plus"}></i>
        </a>
        </h5>
        
        <PopularSearchItem data={data?.allMenus && data?.allMenus?.length > 0 ? data?.allMenus[0] : ""} />
    </div>

    );
};
export default PopularSearchSection;