import React from 'react'
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const ButtonField = ({type, name, value, fieldClass, grpmd, customClass, iconElement, handlClick}) => (
	<div className={`${customClass}`}>
		{
			type === "button" ? 
			<Button variant="" type={type} className={fieldClass} onClick={()=>handlClick(false)}>
				{iconElement ? iconElement : ""}
				{value && <span>{value}</span>}
			</Button>
			:
			<Button variant="" type={type} className={fieldClass}>
				{iconElement ? iconElement : ""}
				{value && <span>{value}</span>}
			</Button>
		}
		
	</div>
);

export default ButtonField;