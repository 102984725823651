import React, { useEffect, useState} from "react";
import { Link } from "gatsby";
import $ from 'jquery';

const MenuList = (props) => {

  //console.log("main_menu_data", props.main_menu_data);
  const [active_submenu, setActiveSubMenu] = useState(-1);


  var menu_data = props.main_menu_data;

  //console.log("active_submenu", active_submenu, props.active_menu);

  useEffect(()=>{
    if(props.active_menu === "615ea43220a53c92c876ea45"){
      setActiveSubMenu("615ea45120a53c92c876ea46");

      $("#accord_submenu_615ea45120a53c92c876ea46").slideDown('slow');
    }
    if(props.active_menu === "615ea51e20a53c92c876ea50"){
      setActiveSubMenu("615ea52f20a53c92c876ea51");

      $("#accord_submenu_615ea52f20a53c92c876ea51").slideDown('slow');
    }
  },[props.active_menu])

  // useEffect(()=>{
  //   $(".accord_icon").on( "click", function() {
  //     $(".menu-list").slideUp();
  //     $(this).parent().next(".menu-list").slideDown();
  //   });
  // },[])

  const getSubMenu = (menu_id) =>{
    $(".service-wrapper .menu-list").not("#accord_menu_"+menu_id).slideUp('slow');
    $("#accord_menu_"+menu_id).slideToggle('slow');     
  }

  const getSubMenu2 = (menu_id) =>{
    $(".service-wrapper .list-wrapper .submenu").not("#accord_submenu_"+menu_id).slideUp('slow');
    $("#accord_submenu_"+menu_id).slideToggle('slow');
    $("#submenu_icon_"+menu_id).toggleClass("icon-plus");
    $("#submenu_icon_"+menu_id).toggleClass("icon-minus");
  }


  return (
    <>
      <div className={`menu-search`}>
        <div className={`service-wrapper ${props.active_menu === props.id ? `open-service-wrapper` : ``}`}>
          
          <div className="service-heading">
            <div className="h5"><Link to={'/'+props.Primary_URL+'/'}>{props.Label} </Link></div>

            {/* {menu_data.length > 0 && (
              props.active_menu === props.id ? 
                <i onClick={() => {props.clickHandler(-1); }} className={`icon-minus accord_icon`}></i> 
                : 
                <i onClick={() => {props.clickHandler(props.id); }} className={`icon-plus accord_icon`}></i>
              )              
            } */}
            {menu_data.length > 0 && (
              <i onClick={() => {props.clickHandler(props.id); getSubMenu(props.id)}} className={`accord_icon ${props.active_menu === props.id ? "icon-minus" : "icon-plus"}`}></i>  
            )}
            
          </div>

          {
            
            <div className={`menu-list`} id={`accord_menu_${props.id}`}>
              <ul className={`list-wrapper ${props.Label.toLowerCase()}`}>
                {menu_data && Object.keys(menu_data).filter(key => menu_data[key].Secondary_Menu === null).map((key, index) => {
                  
                  // props.main_menu_data.filter(item2 => item2.Main_Parent !== null && item2.Secondary_Menu !== null && item2.id === item.id).map(item2 => sub_menu.push(item2));

                  //console.log("main_menu_data", item.Label, props.main_menu_data.filter(item2 => item2.Main_Parent !== null && item2.Secondary_Menu !== null && item2.id === item.id))

                  var getSubMenu = menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Show_Popup_Menu === true && menu_data[key2].Main_Parent !== null && menu_data[key2].Secondary_Menu !== null && menu_data[key2].Secondary_Menu.id === menu_data[key].id));

                  return(
                    <li>
                      <Link to={'/'+props.Primary_URL+'/'+menu_data[key].Primary_URL+'/'}>
                        {/* {menu_data[key].Label.replace('Commercial and ','').replace('Commercial','').replace('Residential and ','').replace('Residential','')} */}
                        {
                          (menu_data[key].Label.indexOf('Commercial and') === 0 || menu_data[key].Label.indexOf('Residential and') === 0) ?
                            menu_data[key].Label 
                          : menu_data[key].Label.replace('Commercial','').replace('Residential','')
                        }
                      </Link>

                      {/* {getSubMenu.length > 0 && (
                        active_submenu === menu_data[key].id ? 
                        <i onClick={() => setActiveSubMenu(-1)} className={`submenu_icon icon-minus`}></i> 
                      : 
                        <i onClick={() => setActiveSubMenu(menu_data[key].id)} className={`submenu_icon icon-plus`}></i>
                      )} */}

                      {getSubMenu.length > 0 && (
                        <i onClick={() => {setActiveSubMenu(menu_data[key].id); getSubMenu2(menu_data[key].id)}} className={`submenu_icon ${(props.active_menu === "615ea43220a53c92c876ea45" || props.active_menu === "615ea51e20a53c92c876ea50") ?"icon-minus" : "icon-plus"}`} id={"submenu_icon_"+menu_data[key].id}></i>
                      )}

                      <ul className={`submenu`} id={`accord_submenu_${menu_data[key].id}`}>
                        {getSubMenu.map((sub_key, i) => (
                          <li>
                            <Link to={'/'+props.Primary_URL+'/'+menu_data[sub_key].Secondary_Menu.Primary_URL+'/'+menu_data[sub_key].Primary_URL+'/'}>{menu_data[sub_key].Label}</Link>
                          </li>
                        ))}
                      </ul>
                        
                    </li>
                  )}
                )}
              </ul>
            </div>
          }
        </div>
      </div>
    </>
  );
};
export default MenuList;
