import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import dateFormat from 'dateformat';

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioboxField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function, CustomLinks, AppraisalFormFieldMapping } from "../common/utils"
import { navigate } from "@reach/router"
import axios from "axios"
import * as qs from "query-string"

import $ from "jquery"

function RequestAppForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [startDate, setStartDate] = useState();

  const [formObjects, setFormObjects] = useState();

  const [formvalues, setFormvalues] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectError, setSelectError] = useState(false);

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var department = props.department ? props.department : props.location?.state?.department ? props.location?.state?.department : "";
  var page_url = props.page_url ? props.page_url : props.location?.state?.page_url ? props.location?.state?.page_url : "";
  var type = props.type ? props.type : props.location?.state?.type ? props.location?.state?.type : "";
  var sub_type = props.sub_type ? props.sub_type : props.location?.state?.sub_type ? props.location?.state?.sub_type : "";
  var to_user_id = props.to_user_id ? props.to_user_id : props.location?.state?.to_user_id ? props.location?.state?.to_user_id : "";

  var fields = [
      {
        element: "config",
        formname: "Request an Appraisal"+ (department ? " - "+department : department),
        form_type: department ? "request_appraisal_"+department: "request_appraisal",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "valuation_user",
        email_temp_admin: "valuation_admin",
        email_server_func: department ? "request_appraisal_"+department: "request_appraisal",
        event_tracking: "request_appraisal",
        page_url: "/request_appraisal",
        email_subject_user: "Request an appraisal",
        email_subject_admin: "Request an appraisal",
      }
    ]    
    
    fields.push({
        grpmd: "12",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed."
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890."
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Next Step",
        class: "btn-primary col-md-12 full_wdth",
        labelClass: "",
        customClass:"btn-wrapper"
      },
    )

  var fields2 = [
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
      {
        grpmd: "12",
        label: "property address",
        placeholder: "",
        name: "property_address",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter the property address."
      },
      {
        grpmd: props.popup_form ? "12" : "6",
        label: "property type",
        placeholder: "",
        name: "property_type",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        divclass: "bed_cls sm_wdth_100",
        errorMsg: "Enter the property type."
      },
      {
        grpmd: props.popup_form ? "12" : "6",
        label: "What are you planning to do with the property?",
        placeholder: "Select",
        name: "property_planning",
        element: "select",
        required: true,
        values: department.trim() === "commercial" ? ['Select', 'Sale', 'Letting', 'Valuation', 'Rent Review', 'Consultancy', 'Other'] : ['Select', 'Sell', 'Let', 'Value'],
        labelClass: "annual-txt",
        class: "full_wdth_box prop_type_opt",
        divclass: "prop_type_opt",
        //errorMsg: "Select an anyone plan."
        errorMsg: "Please provide any other details that might be useful."
      },
      // {
      //   grpmd: "6",
      //   label: "preferred date",
      //   placeholder: "",
      //   name: "preferred_date",
      //   type: "valuation_date_time",
      //   element: "input",
      //   required: true,
      //   // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      //   labelClass: "annual-txt",
      //   class:"date_field"
      // },
      // {
      //   grpmd: "6",
      //   label: "preferred time",
      //   placeholder: "",
      //   name: "preferred_time",
      //   element: "select",
      //   required: true,
      //   values: ['Morning', 'Evening'],
      //   labelClass: "annual-txt",
      //   class: ""
      // },
      {
        grpmd: "12",
        label: "Any other details?",
        placeholder: "﻿Please provide any other helpful information here...",
        name: "message",
        element: "textarea",
        class: "mb-4",
        required: false,
        rows:"4",
        labelClass: "annual-txt"
      },
      {
        grpmd: "6",
        name: "previous",
        type:"button",
        element: "button",
        value: "Previous",
        class: "btn-primary full_wdth",
        labelClass: "",
        customClass:"btn-wrapper prev_btn col-md-6 sm_wdth_100"
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit Enquiry",
        class: "btn-primary full_wdth",
        labelClass: "",
        customClass:"btn-wrapper submit_btn col-md-6 sm_wdth_100"
      },
      {
        text: 'By clicking Submit Enquiry, you agree to our <a href="'+CustomLinks.terms_conditions+'" className="content-link" target="_blank">Terms</a> and <a href="'+CustomLinks.privacy_policy+'" target="_blank">Privacy Policy</a>.',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      
  ];

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("select_event",event.target.value);
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(event);
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

    const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));
      formvalues['g-recaptcha-response'] = token;

      //console.log("formvalues", formvalues)

        if(formvalues.email){
          postFormData(formData).then(async apiRes => {
              window.grecaptcha.reset(); 
          });
          Mail_Function(formvalues);
         
          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': 'form-'+fields[0].event_tracking,
            'formId': 'form-'+fields[0].event_tracking,
            'formName': fields[0].formname,
            //'formLabel': props.formLabel ? props.formLabel : fields[0].formname
            'formLabel': `${department} valuation`
          });

          setShowerror(false);
          // setThankyou(true);
          // $('#popup_modal_form .close').click();
          setTimeout(() => {
            sessionStorage.setItem("previousFormPage", window.location.pathname)
            navigate("/thank-you")
            //$(".openThankPopup").click();
          }, 200);

          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const previousStep = (value) =>{
    setNextStep(value);
    setValidated(false);
    setShowerror(false);
  }

  useEffect(()=>{
    
    if(validated === true && showerror === true){
      formObjects.filter(item => item[1].required === true).map(item =>{
        if(nextStep === false){
          if(item[1].validationMessage !== ""){
            $(".step1 .error_msg."+item[1].name).addClass('show');
          } else{
            $(".step1 .error_msg."+item[1].name).removeClass('show');
          }
        } else{
          
            if(item[1].validationMessage !== ""){
              $(".step2 .error_msg."+item[1].name).addClass('show');
            } else{
              $(".step2 .error_msg."+item[1].name).removeClass('show');
            }
        }
      })
    } else{
      $(".step1 .error_msg").removeClass('show');
      $(".step2 .error_msg").removeClass('show');
    }
  },[formObjects, validated, showerror, nextStep])

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("form ==>", form)

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    setFormObjects(form_obj_arr);

    

    //console.log("selectedOptions", selectedOptions);

    if(nextStep === true){
      

      if(selectedOptions.length === 0){
        setSelectError(true)
        $(".step2 .error_msg.property_planning").addClass('show');
      } else{
        if(selectedOptions.value === "Select"){
          setSelectError(true)
          $(".step2 .error_msg.property_planning").addClass('show');
        } else{
          setSelectError(false)
          $(".step2 .error_msg.property_planning").removeClass('show');
        }
      }
    }
    

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      
      if(nextStep === false && (form.first_name.validationMessage !== "" || form.last_name.validationMessage !== "" || form.email.validationMessage !== "" || form.telephone.validationMessage !== "")){
        setShowerror(true);
        setValidated(true);
        setThankyou(false);
      } else{
        setNextStep(true);  
        setShowerror(false);
        setValidated(false);
      }  
      // if(nextStep === true && (form[12].value === "" || form[13].checked === false || form[14].checked === false || form[15].value === ""  || form[17].value === "")){
      if(nextStep === true && (form.property_address.validationMessage === "" || form.property_type.validationMessage === "" || form.property_planning.validationMessage === "")){
        setShowerror(true);
        setValidated(true);
        setThankyou(false);
      }
      
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      event.preventDefault();

      if(nextStep === false){
        setNextStep(true);
        setValidated(false);
        setShowerror(false);
      } else{
          setNextStep(false);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
          const formsdata = (event.target);
          const json = {}
          Object.keys(formsdata).map(key => (
            //json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
            json[formsdata[key].name] = (formsdata[key].checked === true) ? formsdata[key].value : formsdata[key].value
          ))
  
          var date_time = dateFormat(json.preferred_date, "yyyy-dd-mm");
  
          json['name'] = json.first_name+' '+json.last_name;
          json['email_temp_user'] = fields[0].email_temp_user;
          json['email_temp_admin'] = fields[0].email_temp_admin;
          json['email_subject_user'] = fields[0].email_subject_user;
          json['email_subject_admin'] = fields[0].email_subject_admin;
          json['formname'] = fields[0].formname;
          json['g-recaptcha-response'] = token;
          json['date_time'] = date_time;
          json['enquirytype'] = json.form_type;
          json['message'] = "Property Type: "+ json.property_type+"/"+json.property_planning+'<br>'+json.message;
          // json['extra'] = {"property_address": json.property_address, "property_type": json.property_type, "preferred_date": json.preferred_date, "preferred_time": json.preferred_time, "department": json.department};
          json['extra'] = {"property_address": json.property_address, "department": json.department, "property_url": json.property_url, "property_type": json.property_type, "property_planning": json.property_planning, "type_label": json.type_label, "type": json.type, "sub_type_label": json.sub_type_label, "sub_type": json.sub_type, "to_user_id": json.to_user_id, "subject" : json.email_subject_user};
  
          //console.log("formsdata_json", json);
  
          setFormvalues(json);
  
          recaptchaRef.current.execute();
  
          setValidated(false);
  
          setSelectError(false);
          setSelectedOptions([]);
  
          // reset form
          const form = event.target
          form.reset();
          setStartDate();
      }
    }
  };

  //console.log("props_location 2", props.location);
  
  return (
    <div className="form">
      <div ref={myRef}></div>

      {/* {showerror && <div className="alert alert-danger">
        {fields[0].error_text}
      </div>} */}
      
      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}

    
      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        
        <input type="hidden" name="to_user_id" value={to_user_id} />

        <input type="hidden" name="department" value={department} />
        <input type="hidden" name="property_url" value={page_url} />
        <input type="hidden" name="type_label" value={type} />
        <input type="hidden" name="type" value={type && AppraisalFormFieldMapping.Type[type]} />
        <input type="hidden" name="sub_type_label" value={sub_type} />
        <input type="hidden" name="sub_type" value={sub_type && AppraisalFormFieldMapping.SubType[type][sub_type]} />
        
        <input type="hidden" name="admin_email" value={"No"} />
        
        <input type="hidden" name="bot-field" />

        <Form.Row>
            <div className={`step1 ${nextStep === true ? "hideStep" : "showStep"}`}>
              {/* <h4>Personal Details</h4> */}
              {fields.map((field, index) => {
                  if ( "input" === field.element ) {
                    return (
                      <InputField
                        name={field.name}
                        grpmd={field.grpmd}
                        label={field.label}
                        ref={field.ref}
                        type={field.type}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        labelClass={field.labelClass}
                        required={field.required}
                        key={`${field.element}~${index}`}
                        pattern={field.patternchk}
                        handlechange={handlechange}
                        startDate={field.type === 'valuation_date_time' ? startDate : ''}
                        setStartDate={field.type === 'valuation_date_time' ? setStartDate : ''}
                        errorMsg={field.errorMsg}
                      />
                    );
                  }

                  if ("button" === field.element) {
                    return (
                      <>
                      
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                        grpmd={field.grpmd}
                        customClass={field.customClass}
                      />
                      
                      </>
                    );
                  }
                })
              }
            </div>
            
            {fields2.map((field, index) => {
              if ("captcha" === field.element) {
                  return (
                    <ReCaptchaBox
                      fieldClass={field.class}
                      captRef={field.captchaRef}
                      key={`${field.element}~${index}`}
                      handleonVerify={handleonVerify}
                    />
                  );
                }
              })
            }
          
            <div className={`step2 ${nextStep === false ? "hideStep" : "showStep"}`}>
            {/* <h4>Property Details</h4> */}
            {fields2.map((field, index) => {
                if ( "input" === field.element ) {
                  return (
                    <InputField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      type={field.type}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      pattern={field.patternchk}
                      handlechange={handlechange}
                      startDate={field.type === 'valuation_date_time' ? startDate : ''}
                      setStartDate={field.type === 'valuation_date_time' ? setStartDate : ''}
                      errorMsg={field.errorMsg}
                      divclass={field.divclass}
                    />
                  );
                }
                if ("select" === field.element) {
                  return (
                    <SelectField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                      divclass={field.divclass}
                      selectedValue={selectedOptions}
                      errorMsg={field.errorMsg}
                      isError={selectError ? true : '' }
                    />
                  );
                }

                if ("radio" === field.element) {
                  return (
                    <RadioboxField
                      name={field.name}
                      ref={field.ref}
                      values={field.value}
                      fieldClass={field.class}
                      label={field.label}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.name}~${index}`}
                      handlechange={handlechange}
                    />
                  );
                }
                if ("textarea" === field.element) {
                  return (
                    <>
                    
                    <TextAreaField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      rows={field.rows}
                      fieldClass={field.class}
                      labelClass={field.labelClass}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                    />
                    </>
                  );
                }
                if ("html" === field.element) {
                  return (
                    <HtmlBox
                      text={field.text}
                      fieldClass={field.class}
                      key={`${field.element}~${index}`}
                    />
                  );
                }
                
                if ("button" === field.element) {
                  return (
                    <>
                    {field.name === "previous" ? 
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                        grpmd={field.grpmd}
                        customClass={field.customClass}
                        handlClick={previousStep}
                      />
                      :
                      <ButtonField
                        name={field.name}
                        fieldClass={field.class}
                        type={field.type}
                        value={field.value}
                        key={`${field.element}~${index}`}
                        grpmd={field.grpmd}
                        customClass={field.customClass}
                      />
                      
                    }
                    </>
                  );
                }
              })
            }
            </div>          
        </Form.Row>
        
      </Form>
    </div>
  );
}


const RequestAppPage = (props) => (
  <RequestAppForm {...props} />
  
)

export default RequestAppPage
