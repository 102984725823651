import React, { useState} from "react";
import { Col } from "react-bootstrap";
import { Link } from "gatsby";
import PhoneIcon from '../../images/footer/phoneIcon.svg'

const FooterMenu = (props) => {
  const [active_submenu, setActiveSubMenu] = useState(-1);
  var menu_data = props.main_menu_data;
  var isContact;

  return (
    <>
      <div className={`footer-menu ${props.Primary_URL}`}>
        <Col>
          <div className={`menu-wrapper ${props.isActive ? `open-menu-wrapper` : ``}`}>
            <div className="open">
              <h5><Link to={'/'+props.Primary_URL+'/'}>{props.Label}</Link></h5>
              <i onClick={() => props.clickHandler(props.i)} className={props.active_menu === props.i ? "icon-minus" : "icon-plus"}></i>
            </div>            
            <div className={`menu-list ${props.active_menu === props.i ? "show" : "hide"}`}>
              <ul>
                {menu_data && Object.keys(menu_data).filter(key => menu_data[key].Secondary_Menu === null).map((key, i) => {
                  var getSubMenu = menu_data && Object.keys(menu_data).filter(key2 => (menu_data[key2].Main_Parent !== null && menu_data[key2].Secondary_Menu !== null && menu_data[key2].Secondary_Menu.id === menu_data[key].id));
                   isContact = menu_data[key].Main_Parent.Label === "Contact"
                  return(
                    <li key={i}>
                      <Link to={'/'+props.Primary_URL+'/'+menu_data[key].Primary_URL+'/'}>
                        {
                          (menu_data[key].Label.indexOf('Commercial and') === 0 || menu_data[key].Label.indexOf('Residential and') === 0) ?
                            menu_data[key].Label 
                          : menu_data[key].Label.replace('Commercial','').replace('Residential','').replace('Auctions', 'Nationwide Property Auctions')
                        }
                        </Link>
                        {isContact ?
                          <div className="phone-section">
                            <img src={PhoneIcon} alt='phoneimg'/>
                            <a href={`tel:${menu_data[key].Footer_Phone_Number}`}>{menu_data[key].Footer_Phone_Number}</a>
                          </div>
                        : ""}

                        {/* {getSubMenu.length > 0 && (
                          active_submenu === menu_data[key].id ? 
                          <i onClick={() => setActiveSubMenu(-1)} className={`submenu_icon icon-minus`} data-id={menu_data[key].id}></i> 
                        : 
                          <i onClick={() => setActiveSubMenu(menu_data[key].id)} data-id={menu_data[key].id} className={`submenu_icon icon-plus`}></i>
                        )} */}

                        {
                          active_submenu === menu_data[key].id && 
                          <ul className="submenu">
                            {getSubMenu.map((sub_key, i) => (
                              <li key={i}>
                                <Link to={'/'+props.Primary_URL+'/'+menu_data[sub_key].Secondary_Menu.Primary_URL+'/'+menu_data[sub_key].Primary_URL}>{menu_data[sub_key].Label+'/'}</Link>
                              </li>
                            ))}
                          </ul>
                        }
                    </li>
                  )}
                )}
              </ul>
            </div>
          </div>
        </Col>
      </div>
    </>
  );
};
export default FooterMenu;
